<template>
  <section class="facilities-container">
    <h5>Amenities</h5>
    <!-- Sports -->
    <div class="facility-icons"  style="display: flex; flex-flow: row wrap; justify-content: center;" v-if="sportsCheck">
      <h6>Sports</h6>
      <div class="facility-card">
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.gym"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/fitness.png" alt />
              </div>
            </div>
            <p>Gym</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.billard"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/billiards.png" alt />
              </div>
            </div>
            <p>Billiard</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.tennisCourt"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/court.png" alt />
              </div>
            </div>
            <p>Tennis Court</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.airHockeyTable"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/hockey.png" alt />
              </div>
            </div>
            <p>AirHockey</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.soccerField"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/football-field.png" alt />
              </div>
            </div>
            <p>Soccer Field</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.trampoline"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/trampoline.png" alt />
              </div>
            </div>
            <p>Trampoline</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.volleyBall"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/beach-volleyball.png" alt />
              </div>
            </div>
            <p>Volley Ball</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Sports -->
    <!-- Leisure -->
    <div class="facility-icons"  style="display: flex; flex-flow: row wrap; justify-content: center;" v-if="Leisure">
      <h6>Leisure</h6>
      <div class="facility-card">
        <div
          class="icon-card"
          v-if="
            service &&
            service.swimmingWithWaterGames &&
            service.leisure.swimmingWithWaterGames
          "
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/swimming-pool.png" alt />
              </div>
            </div>
            <p>Swimming With Water Games</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.steamBath"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/hot-bath.png" alt />
              </div>
            </div>
            <p>Steam Bath</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.airSlider"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/airSlider.png" alt />
              </div>
            </div>
            <p>Air Slider</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.sandGames"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/sandGames.png" alt />
              </div>
            </div>
            <p>Sand Games</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.waterGames"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/waterGames.png" alt />
              </div>
            </div>
            <p>Water Games</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="
            service && service.leisure && service.leisure.indoorSwimmingPool
          "
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/swimming-pool.png" alt />
              </div>
            </div>
            <p>InDoor Swimming Pool</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="
            service && service.leisure && service.leisure.outdoorSwimmingPool
          "
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/swimming-pool.png" alt />
              </div>
            </div>
            <p>OutDoor Swimming Pool</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="
            service && service.leisure && service.leisure.swimmingPoolWithHeat
          "
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/heater.png" alt />
              </div>
            </div>
            <p>SwimmingPool with Heater</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.hairStylist"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/hairstylist.png" alt />
              </div>
            </div>
            <p>HairStylist</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.playground"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/playground.png" alt />
              </div>
            </div>
            <p>Playground</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.spa"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/hot-bath.png" alt />
              </div>
            </div>
            <p>Spa</p>
          </div>
        </div>
        <div
          class="icon-card"
          v-if="service && service.leisure && service.leisure.hotTub"
        >
          <div>
            <div class="icon-img">
              <div>
                <img src="../../../assets/images/hotTub.png" alt />
              </div>
            </div>
            <p>Hot Tub</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Home Amenities -->
    <div
      style="display: flex; flex-flow: row wrap; justify-content: center;"
      v-for="(item, index) in group"
      :key="index"
      class="facility-icons"
    >
      <h6>{{ item?.type }}</h6>
      <div
        v-for="(opt, subIndex) in item?.items"
        :key="subIndex"
        class="facility-card"
      >
        <div class="icon-card item">
          <div>
            <div class="icon-img">
              <div>
                <img :src="opt._id.image" alt />
              </div>
            </div>
            <p class="title">
              {{ opt._id?.title }}
              <label v-if="opt._id.isNumber"> :{{ opt.value }} </label>
            </p>
          </div>
        </div>
        <!-- <div
            class="icon-card"
            v-if="
              service &&
              service.leisure &&
              service.leisure.weelChaireAccess
            "
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/wheelchair.png" alt />
                </div>
              </div>
              <p>WheelChair</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="
              service &&
              service.leisure &&
              service.leisure.privateEntrance
            "
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/entrance.png" alt />
                </div>
              </div>
              <p>Private Entrance</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="service && service.leisure && service.leisure.doorman"
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/doorman.png" alt />
                </div>
              </div>
              <p>Doorman</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="service && service.leisure && service.leisure.parking"
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/parking.png" alt />
                </div>
              </div>
              <p>Parking</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="service && service.leisure && service.leisure.elevator"
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/elevator.png" alt />
                </div>
              </div>
              <p>Elevator</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="service && service.leisure && service.leisure.saftyBox"
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/saftyBox.png" alt />
                </div>
              </div>
              <p>Safty Box</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="
              service &&
              service.leisure &&
              service.leisure.airConditioning
            "
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/air-conditioner.png" alt />
                </div>
              </div>
              <p>Conditioning</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="service && service.leisure && service.leisure.tv"
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/tv.png" alt />
                </div>
              </div>
              <p>TV</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="service && service.leisure && service.leisure.wifi"
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/wifi.png" alt />
                </div>
              </div>
              <p>WiFi</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="service && service.leisure && service.leisure.speakers"
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/speakers.png" alt />
                </div>
              </div>
              <p>Speakers</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="service && service.leisure && service.leisure.stage"
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/stage.png" alt />
                </div>
              </div>
              <p>Stage</p>
            </div>
          </div>
          <div
            class="icon-card"
            v-if="
              service && service.leisure && service.leisure.laserLights
            "
          >
            <div>
              <div class="icon-img">
                <div>
                  <img src="../../../assets/images/laser.png" alt />
                </div>
              </div>
              <p>Laser Lights</p>
            </div>
          </div> -->
      </div>
    </div>
    <!-- Home Amenities -->
  </section>
</template>

<script>
export default {
  name: "DetailFacilities",
  props: ["service"],
  data() {
    return {
      facilities: [],
      group: [],
    };
  },
  mounted() {
    this.facilities = this.service.facilities;
    console.log(this.service);

    this.groupedData(this.service.facilities);
  },
  methods: {
    groupedData() {
      // Group by type and structure the data accordingly
      const grouped = this.facilities.reduce((acc, item) => {
        const { type } = item;
        const existingGroup = acc.find((group) => group.type === type);

        if (!existingGroup) {
          // If no group for this type exists, create one
          acc.push({ type: type, items: [item] });
        } else {
          // If group exists, push the item into the existing group
          existingGroup.items.push(item);
        }
        return acc;
      }, []);
      this.group = grouped;
    },
  },
  computed: {
    sportsCheck: function () {
      if (
        (this.service.leisure && this.service.leisure.gym) ||
        (this.service.leisure && this.service.leisure.billard) ||
        (this.service.leisure && this.service.leisure.tennisCourt) ||
        (this.service.leisure && this.service.leisure.soccerField) ||
        (this.service.leisure && this.service.leisure.trampoline) ||
        (this.service.leisure && this.service.leisure.volleyBall) ||
        (this.service.leisure && this.service.leisure.airHockeyTable)
      ) {
        return true;
      } else {
        return false;
      }
    },
    Leisure: function () {
      if (
        (this.service.leisure && this.service.leisure.steamBath) ||
        (this.service.leisure && this.service.leisure.indoorSwimmingPool) ||
        (this.service.leisure && this.service.leisure.outdoorSwimmingPool) ||
        (this.service.leisure && this.service.leisure.hairStylist) ||
        (this.service.leisure && this.service.leisure.airSlider) ||
        (this.service.leisure && this.service.leisure.sandGames) ||
        (this.service.leisure && this.service.leisure.waterGames) ||
        (this.service.leisure && this.service.leisure.swimmingPoolWithHeat) ||
        (this.service.leisure && this.service.leisure.playground) ||
        (this.service.leisure && this.service.leisure.spa) ||
        (this.service.leisure && this.service.leisure.hotTub) ||
        (this.service.swimmingWithWaterGames &&
          this.service.leisure.swimmingWithWaterGames)
      ) {
        return true;
      } else {
        return false;
      }
    },
    Amenities: function () {
      if (
        (this.service.leisure && this.service.leisure.weelChaireAccess) ||
        (this.service.leisure && this.service.leisure.privateEntrance) ||
        (this.service.leisure && this.service.leisure.doorman) ||
        (this.service.leisure && this.service.leisure.parking) ||
        (this.service.leisure && this.service.leisure.elevator) ||
        (this.service.leisure && this.service.leisure.saftyBox) ||
        (this.service.leisure && this.service.leisure.tv) ||
        (this.service.leisure && this.service.leisure.wifi) ||
        (this.service.leisure && this.service.leisure.speakers) ||
        (this.service.leisure && this.service.leisure.stage) ||
        (this.service.leisure && this.service.leisure.accessInHours) ||
        (this.service.leisure && this.service.leisure.laserLights)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.facilities-container {
  background: rgb(244, 247, 250);
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  max-width: 70%;
  margin: 0 auto;
}
.facilities-container h5 {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  padding-bottom: 50px;
}
.facility-icons {
  padding: 0px 0 50px 0;
}

.facility-icons h6 {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  width: 100%;
  font-size: 14px;
}
.facility-card {
  display: flex;
  padding-top: 30px;
  flex-wrap: wrap;
}
.facility-card .icon-card {
  width: 105px;
  display: flex;
  justify-content: space-between;

  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.facility-card .icon-card > div {
  /* width: 90px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.facility-card .icon-card .icon-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px #00000012;
  opacity: 1;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.facility-card .icon-card .icon-img div {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.facility-card .icon-card .icon-img img {
  width: 100%;
  height: 100%;
}
.facility-card .icon-card p {
  font-size: 14px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  padding: 10px 0;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* white-space: nowrap; */
}
.title {
  margin-top: 5px;
}

.number {
  margin-top: 5px;
  font-weight: bold;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.set-lang .facilities-container h5,
.set-lang .facility-icons h6 {
  text-align: right;
}
@media (max-width: 700px) and (min-width: 320px) {
}
</style>
